import React, { useEffect } from 'react'
import LottieAnim from '../lotties/LottieAnim'
import animationData from '../lotties/lottiefiles/brick-loader'

const Preloader = () => {
  useEffect(() => {
    document.body.classList.remove('has-navbar-fixed-top')
    return () => {
      document.body.classList.add('has-navbar-fixed-top')
    }
  }, [])
  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container center'>
          {/* <button className='button is-text is-loading is-large'></button> */}
          <LottieAnim animationData={animationData} />
        </div>
      </div>
    </section>

    // <section className='hero is-fullheight'>
    //   <div className='section'>
    //     <div className='hero-body'>
    //       <div className='container'>
    //         <h3 className='title has-text-centered'>Viju Group</h3>
    //         <LottieAnim animationData={animationData} />
    //       </div>
    //       <br />
    //       <div className=''></div>
    //       {/* <div className='container center'> */}
    //       {/* <LottieAnim animationData={animationData}></LottieAnim> */}
    //       {/* <button className='button is-large is-text is-loading'>
    //         <span className='icon is-medium'>
    //         <i className='fas fa-spin fa-3x'></i>
    //         </span>
    //       </button> */}
    //       {/* </div> */}
    //     </div>
    //   </div>
    // </section>
  )
}

export default Preloader
