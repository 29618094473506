import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Preloader from './components/layout/Preloader'
import smoothscroll from 'smoothscroll-polyfill'
import CookConsent from './components/layout/CookConsent'

const HomePage = lazy(() => import('./pages/HomePage'))
const ContactPage = lazy(() => import('./pages/ContactPage'))
const NotFound404 = lazy(() => import('./pages/NotFound404'))

const App = () => {
  smoothscroll.polyfill()

  return (
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <div className='App'>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/contact' component={ContactPage} />
            {/* <Route path='/login' component={LoginPage} /> */}
            <Route path='*' component={NotFound404} />
          </Switch>
          <CookConsent />
        </div>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
